import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Voltage(subtype: number = 0): ComponentTypeExtension {
  let voltage = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE,
    quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_VOLTAGE,
    subtypes: [],
    friendlyName: "Voltage",
    description: "Measures voltage",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(voltage),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, voltage);
    },
    minMeasurementPeriodMs: 1000
  };
}
