import { ComponentTypeExtension, Summary } from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";

export function Invalid(subtype: number = 0): ComponentTypeExtension {
  return {
    type: quack.ComponentType.COMPONENT_TYPE_INVALID,
    subtypes: [],
    friendlyName: "Invalid",
    description: "",
    isController: false,
    isSource: false,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_INVALID],
    interactionResultTypes: [quack.InteractionResultType.INTERACTION_RESULT_TYPE_INVALID],
    states: [],
    measurements: [],
    measurementSummary: async function(): Promise<Array<Summary>> {
      return Promise.reject();
    },
    minMeasurementPeriodMs: 0
  };
}
