import FuelDark from "assets/components/fuelDark.png";
import FuelLight from "assets/components/fuelLight.png";
import DirectionDark from "assets/components/directionDark.png";
import DirectionLight from "assets/components/directionLight.png";
import {
  ComponentTypeExtension,
  simpleMeasurements,
  simpleSummaries,
  Summary
} from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { quack } from "protobuf-ts/quack";

export function AnalogInput(subtype: number = 0): ComponentTypeExtension {
  let analog = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_ANALOG,
    quack.ComponentType.COMPONENT_TYPE_ANALOG_INPUT,
    subtype
  );

  let myIcon;

  switch (subtype) {
    case quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_FUEL:
      myIcon = (theme?: "light" | "dark") => {
        return theme === "light" ? FuelDark : FuelLight;
      };
      break;
    case quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_WIND_DIRECTION:
      myIcon = (theme?: "light" | "dark") => {
        return theme === "light" ? DirectionDark : DirectionLight;
      };
      break;
  }

  return {
    type: quack.ComponentType.COMPONENT_TYPE_ANALOG_INPUT,
    subtypes: [
      {
        key: quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_NONE,
        value: "ANALOG_INPUT_SUBTYPE_NONE",
        friendlyName: "Analog Input"
      },
      {
        key: quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_FUEL,
        value: "ANALOG_INPUT_SUBTYPE_FUEL",
        friendlyName: "Fuel"
      },
      {
        key: quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_WIND_DIRECTION,
        value: "ANALOG_INPUT_SUBTYPE_WIND_DIRECTION",
        friendlyName: "Wind Direction"
      },
      {
        key: quack.AnalogInputSubtype.ANALOG_INPUT_SUBTYPE_CURRENT_TRANSFORMER,
        value: "ANALOG_INPUT_SUBTYPE_CURRENT_TRANSFORMER",
        friendlyName: "Current Transformer"
      }
    ],
    friendlyName: "Analog Input",
    description: "Measures a raw analog input",
    isController: false,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(analog),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, analog);
    },
    minMeasurementPeriodMs: 1000,
    icon: myIcon
  };
}
