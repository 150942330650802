import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Light(subtype: number = 0): ComponentTypeExtension {
  let light = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_LIGHT,
    quack.ComponentType.COMPONENT_TYPE_LIGHT,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_LIGHT,
    subtypes: [],
    friendlyName: "Light",
    description: "Measures the intensity of light",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(light),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, light);
    },
    minMeasurementPeriodMs: 1000
  };
}
