import { Card, CircularProgress, Typography } from "@material-ui/core";
import { blue, grey, red, yellow, orange } from "@material-ui/core/colors";
import BarGraph, { BarData, RefArea } from "charts/BarGraph";
import { Bin } from "models";
import moment, { Moment } from "moment";
import { pond } from "protobuf-ts/pond";
import { useBinAPI } from "providers";
import React, { useEffect, useState } from "react";
import { Legend } from "recharts";
import { getGrainUnit } from "utils";

interface Props {
  binLoading: boolean;
  bin: Bin;
  colour: string;
  fertilizerBin: boolean;
  startDate: Moment;
  endDate: Moment;
  customHeight?: number | string;
}

export default function BinLevelOverTime(props: Props) {
  const { bin, fertilizerBin, colour, startDate, endDate, customHeight } = props;
  const binAPI = useBinAPI();
  const [data, setData] = useState<BarData[]>([]);
  const [modeAreas, setModeAreas] = useState<RefArea[]>([]);
  // const defaultDateRange = GetDefaultDateRange();
  // const [startDate, setStartDate] = useState<Moment>(defaultDateRange.start);
  // const [endDate, setEndDate] = useState<Moment>(defaultDateRange.end);
  const [dataLoading, setDataLoading] = useState(false);
  const [capacity, setCapacity] = useState<number | undefined>();

  // const updateDateRange = (newStartDate: any, newEndDate: any) => {
  //   let range = GetDefaultDateRange();
  //   range.start = newStartDate;
  //   range.end = newEndDate;
  //   setStartDate(newStartDate);
  //   setEndDate(newEndDate);
  // };

  const getFill = (mode: pond.BinMode) => {
    let fill = "";
    switch (mode) {
      case pond.BinMode.BIN_MODE_STORAGE:
        fill = yellow[500];
        break;
      case pond.BinMode.BIN_MODE_COOLDOWN:
        fill = orange[500];
        break;
      case pond.BinMode.BIN_MODE_DRYING:
        fill = red[500];
        break;
      case pond.BinMode.BIN_MODE_HYDRATING:
        fill = blue[500];
        break;
      default:
        fill = grey[500];
        break;
    }
    return fill;
  };

  useEffect(() => {
    setDataLoading(true);
    let cap = bin.settings.specs?.bushelCapacity;
    if (fertilizerBin && cap) {
      cap = cap * 35.239;
    }
    if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && cap) {
      cap = cap / bin.bushelsPerTonne();
    }
    setCapacity(cap);
    binAPI
      .listHistoryBetween(bin.key(), 500, startDate.toISOString(), endDate.toISOString())
      .then(resp => {
        let data: BarData[] = [];
        let modeAreas: RefArea[] = [];
        let lastBushels = -1;

        //values for ref areas
        let x1 = 0;
        let x2 = 0;
        let y1 = -50;
        let y2 = -200;

        let currentMode: pond.BinMode;

        resp.data.history.forEach(hist => {
          if (hist.settings?.inventory) {
            let settings = pond.BinSettings.fromObject(hist.settings);
            let bushels = hist.settings.inventory.grainBushels ?? 0;
            if (bushels !== lastBushels || currentMode !== settings.mode) {
              x1 = moment(hist.timestamp).valueOf();
              x2 = moment(hist.timestamp).valueOf();
              modeAreas.push({
                x1: x1,
                x2: x2,
                y1: cap ? cap * -0.1 : y1,
                y2: cap ? cap * -0.2 : y2,
                fill: getFill(settings.mode)
              });
              currentMode = settings.mode;
              let newData: BarData = {
                timestamp: moment(hist.timestamp).valueOf(),
                value: fertilizerBin
                  ? Math.round(bushels * 35.239)
                  : getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT
                  ? Math.round((bushels / bin.bushelsPerTonne()) * 100) / 100
                  : bushels
              };
              data.push(newData);
              lastBushels = bushels;
            }
          }
        });
        if (data.length === 0) {
          let bushels = bin.settings.inventory?.grainBushels ?? 0;
          let currentTime = moment().valueOf();
          data.push({
            value: fertilizerBin
              ? Math.round(bushels * 35.239)
              : getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT
              ? Math.round((bushels / bin.bushelsPerTonne()) * 100) / 100
              : bushels,
            timestamp: currentTime
          });

          modeAreas.push({
            x1: currentTime,
            x2: currentTime,
            y1: cap ? cap * -0.1 : y1,
            y2: cap ? cap * -0.2 : y2,
            fill: getFill(bin.settings.mode)
          });
        }
        setData(data);
        setModeAreas(modeAreas);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [binAPI, bin, startDate, endDate, fertilizerBin]);

  const legend = () => {
    return (
      <Legend
        verticalAlign="bottom"
        payload={[
          { value: "Drying", type: "square", id: "drying", color: red[500] },
          { value: "Cooldown", type: "square", id: "cooldown", color: orange[500] },
          { value: "Storage", type: "square", id: "storage", color: yellow[500] },
          { value: "Hydrating", type: "square", id: "hydrating", color: blue[500] }
        ]}
      />
    );
  };

  return (
    <React.Fragment>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <Card raised style={{ padding: 5 }}>
          <Typography style={{ fontSize: 25, fontWeight: 650, marginLeft: 20 }}>
            Grain Levels Over Time
          </Typography>
          <BarGraph
            customHeight={customHeight}
            data={data}
            refAreas={modeAreas}
            barColour={colour}
            yMax={capacity}
            graphLegend={legend()}
            labelColour="white"
            labels
            useGradient
          />
        </Card>
      )}
    </React.Fragment>
  );
}
