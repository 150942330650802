import { Box, List, ListItem, Typography } from "@material-ui/core";
import EndpointDisplay, { EndPointData } from "apiDocs/endpointDisplay";
import React from "react";

/**
 * endpoint template
        {
            requestType: "",
            url: "https://api.brandxtech.ca/v1/",
            description: "",
            urlOptions: [],
            urlParams: [],
            requestBody: "",
            responseParams: []
        },
 */

export default function ConstructionEndpoints() {
  const jobsiteEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/sites",
      description: "get a list of jobsites",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated Site sites", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/sites/:site",
      description: "get a specific site",
      urlParams: [":site - the key of the site"],
      responseParams: ["SiteSettings settings", "SiteStatus status"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/sites",
    //     description: "add a new jobsite",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/sites/:site/link",
    //     description: "update the devices linked to a site",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/sites/:site",
    //     description: "update an existing jobsite",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/sites/:site",
    //     description: "",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const heaterEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/objectHeaters",
      description:
        "get a list of heater objects, note these are different than the heater components on a device",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated ObjectHeater heaters", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/objectHeaters/:objectHeater",
      description:
        "get a specific object heater, not this is different than a heater component on a device",
      urlParams: [":objectHeater - the key of the heater object"],
      responseParams: ["ObjectHeater heater"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/objectHeaters",
    //     description: "add a new heater object",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/objectHeaters/:objectHeater/link",
    //     description: "update the device connected to the heater",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/objectHeaters/:objectHeater",
    //     description: "update an existing heater",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/objectHeaters/:objectHeater",
    //     description: "delete an existing heater",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving jobsite data
      </Typography>
      <List>
        {jobsiteEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving heater data
      </Typography>
      <List>
        {heaterEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
