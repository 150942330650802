import { useHTTP } from "hooks";
import moment from "moment";
import { useGlobalState } from "providers";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IUsageAPIContext {
  getUsage: (
    deviceID: number | string,
    startDate?: any,
    demo?: boolean,
    keys?: string[],
    types?: string[]
  ) => Promise<any>;
  getUsages: (deviceIDs: number[], startDate?: any) => Promise<any>;
  listUsages: (startDate?: any) => Promise<any>;
}

export const UsageAPIContext = createContext<IUsageAPIContext>({} as IUsageAPIContext);

function usageQuery(deviceIDs?: number[], startDate?: any): string {
  let query = "";
  let symbol: "?" | "&" = "?";
  if (deviceIDs && deviceIDs.length > 0) {
    query = query.concat(symbol + "ids=" + deviceIDs.join(","));
    symbol = "&";
  }
  if (startDate) {
    let start = moment(startDate).toISOString();
    query = query.concat(symbol + "start=" + start);
    symbol = "&";
  }
  return query;
}

interface Props {}

export default function UsageProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get } = useHTTP();
  const [{ as }] = useGlobalState();

  const getUsage = (
    deviceID: number | string,
    startDate?: any,
    demo: boolean = false,
    keys?: string[],
    types?: string[]
  ) => {
    if (as)
      return get(
        pondURL(
          "/devices/" +
            deviceID +
            "/usage" +
            usageQuery(undefined, startDate) +
            "&as=" +
            as +
            (keys ? "&keys=" + keys.toString() : "") +
            (types ? "&types=" + types.toString() : ""),
          demo
        )
      );
    return get(
      pondURL(
        "/devices/" +
          deviceID +
          "/usage" +
          usageQuery(undefined, startDate) +
          (keys ? "&keys=" + keys.toString() : "") +
          (types ? "&types=" + types.toString() : ""),
        demo
      )
    );
  };

  const getUsages = (deviceIDs: number[], startDate?: any) => {
    if (as) return get(pondURL("/usage/devices" + usageQuery(deviceIDs, startDate) + "&as=" + as));
    return get(pondURL("/usage/devices" + usageQuery(deviceIDs, startDate)));
  };

  const listUsages = (startDate?: any) => {
    if (as) return get(pondURL("/usage" + usageQuery(undefined, startDate) + "&as=" + as));
    return get(pondURL("/usage" + usageQuery(undefined, startDate)));
  };

  return (
    <UsageAPIContext.Provider
      value={{
        getUsage,
        getUsages,
        listUsages
      }}>
      {children}
    </UsageAPIContext.Provider>
  );
}

export const useUsageAPI = () => useContext(UsageAPIContext);
