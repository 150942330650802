import {
  ComponentTypeExtension,
  simpleMeasurements,
  simpleSummaries,
  Subtype,
  Summary
} from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { quack } from "protobuf-ts/quack";
import PowerDarkIcon from "assets/components/powerDark.png";
import PowerLightIcon from "assets/components/powerLight.png";

export function Power(subtype: number = 0): ComponentTypeExtension {
  let voltage = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE,
    quack.ComponentType.COMPONENT_TYPE_POWER,
    subtype
  );
  let charged = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT,
    quack.ComponentType.COMPONENT_TYPE_POWER,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_POWER,
    subtypes: [
      {
        key: quack.PowerSubtype.POWER_SUBTYPE_NONE,
        value: "POWER_SUBTYPE_NONE",
        friendlyName: "Battery"
      } as Subtype,
      {
        key: quack.PowerSubtype.POWER_SUBTYPE_NO_BATTERY,
        value: "POWER_SUBTYPE_NO_BATTERY",
        friendlyName: "Power Supply"
      } as Subtype
    ],
    friendlyName: "Power",
    description: "Measures the power source for a device (voltage and charge percent)",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_POWER],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(voltage, charged),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, voltage, charged);
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? PowerDarkIcon : PowerLightIcon;
    }
  };
}
