import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import ModemDarkIcon from "assets/components/modemDark.png";
import ModemLightIcon from "assets/components/modemLight.png";

export function Modem(subtype: number = 0): ComponentTypeExtension {
  let quality = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT,
    quack.ComponentType.COMPONENT_TYPE_MODEM,
    subtype
  );
  let rssi = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_RSSI,
    quack.ComponentType.COMPONENT_TYPE_MODEM,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_MODEM,
    subtypes: [],
    friendlyName: "Modem",
    description: "Measures network strength and quality",
    isController: false,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_MODEM],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(quality, rssi),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, quality, rssi);
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? ModemDarkIcon : ModemLightIcon;
    }
  };
}
