import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from "@material-ui/core";
import ResponsiveDialog from "common/ResponsiveDialog";
import { teamScope, User } from "models";
import { pond } from "protobuf-ts/pond";
import { useSnackbar, useUserAPI } from "providers";
import { useCNHiProxyAPI } from "providers/pond/cnhiProxyAPI";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import TeamSearch from "teams/TeamSearch";

export default function CNHiAccess() {
  const [openDialog, setOpenDialog] = useState(false);
  const [teamKey, setTeamKey] = useState("");
  const [teamUsers, setTeamUsers] = useState<User[]>([]);
  const [primaryUser, setPrimaryUser] = useState("");
  const [cnhiUsername, setCNHiUserName] = useState("");
  const [cnhiCode, setCNHiCode] = useState<string | null>("");
  //const [activeStep, setActiveStep] = useState<number>(0);
  const userAPI = useUserAPI();
  const cnhiAPI = useCNHiProxyAPI();
  //const [dataOps, setDataOps] = useState<pond.DataOption[]>([]);
  const { openSnack } = useSnackbar();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  //const [{ as }] = useGlobalState();
  const client_id = process.env.REACT_APP_CNHI_CLIENT_ID;
  const auth_url = process.env.REACT_APP_CNHI_AUTHORIZE_URL;
  const redirect_url = process.env.REACT_APP_CNHI_REDIRECT_URI;
  const scopes = process.env.REACT_APP_CNHI_SCOPES;
  const connection = process.env.REACT_APP_CNHI_CONNECTION;
  const audience = process.env.REACT_APP_CNHI_AUDIENCE;

  const submitNewOrganization = () => {
    if (cnhiCode) {
      cnhiAPI
        .addAccount(teamKey, primaryUser, cnhiCode, cnhiUsername)
        .then(resp => {
          openSnack("Added New Case New Holland Account Link");
        })
        .catch(err => {
          openSnack("Failed to Add New Case New Holland Account Link");
        });
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    if (teamKey !== "") {
      userAPI.listObjectUsers(teamScope(teamKey)).then(resp => {
        setTeamUsers(resp.data.users.map((u: pond.User) => User.any(u)));
      });
    }
  }, [teamKey, userAPI]);

  useEffect(() => {
    let code = searchParams.get("code");
    if (code && code !== cnhiCode) {
      setCNHiCode(code);
      setOpenDialog(true);
    }
  }, [searchParams, cnhiCode]);

  const validate = () => {
    let invalid = false;
    if (cnhiUsername === "" || teamKey === "" || primaryUser === "" || cnhiCode === "") {
      invalid = true;
    }
    return invalid;
  };

  const general = () => {
    return (
      <Box>
        <TextField
          margin="dense"
          label="Case New Holland Email"
          fullWidth
          variant="outlined"
          value={cnhiUsername}
          onChange={e => setCNHiUserName(e.target.value)}
        />
        <TeamSearch label="Team" setTeamCallback={setTeamKey} />
        <TextField
          disabled={teamKey === ""}
          margin="dense"
          label="Primary User"
          select
          fullWidth
          variant="outlined"
          value={primaryUser}
          onChange={e => setPrimaryUser(e.target.value)}>
          {teamUsers.map(u => (
            <MenuItem key={u.id()} value={u.id()}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Avatar
                    alt={u.name()}
                    src={
                      u.settings.avatar && u.settings.avatar !== "" ? u.settings.avatar : undefined
                    }
                  />
                </Grid>
                <Grid item>
                  <Box marginLeft={2}>{u.name()}</Box>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          label="code"
          disabled
          fullWidth
          variant="outlined"
          value={cnhiCode}
          onChange={e => setCNHiCode(e.target.value)}
        />
      </Box>
    );
  };

  const newOrgDialog = () => {
    return (
      <ResponsiveDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}>
        <DialogTitle>Enter New Integration Link</DialogTitle>
        <DialogContent>{general()}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}>
            Close
          </Button>
          <Button
            disabled={validate()}
            onClick={submitNewOrganization}
            variant="contained"
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  return (
    <Box style={{ padding: 10 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={e => {
          e.preventDefault();
          window.open(
            `${auth_url}?client_id=${client_id}&response_type=code&scope=${scopes}&redirect_uri=${redirect_url}&audience=${audience}&connection=${connection}`,
            "_blank"
          );
        }}>
        Link Case New Holland Account
      </Button>
      {newOrgDialog()}

      {/* Admin testing buttons */}
      {/*       
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}>
        2. Choose team and user
      </Button>
      <Button
        onClick={() => {
          cnhiAPI
            .listFields(100, 0, as)
            .then(resp => {
              console.log(resp);
            })
            .catch(err => {
              console.log("errors found");
            });
        }}>
        Field List test
      </Button> */}
    </Box>
  );
}
