import TemperatureHumidityDarkIcon from "assets/components/temperatureHumidityDark.png";
import TemperatureHumidityLightIcon from "assets/components/temperatureHumidityLight.png";
import {
  ComponentTypeExtension,
  simpleMeasurements,
  simpleSummaries,
  Summary
} from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { quack } from "protobuf-ts/quack";

export function DHT(subtype: number = 0): ComponentTypeExtension {
  let temperature = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE,
    quack.ComponentType.COMPONENT_TYPE_DHT,
    subtype
  );
  let humidity = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT,
    quack.ComponentType.COMPONENT_TYPE_DHT,
    subtype
  );
  let addressTypes = [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY];
  if (
    subtype === quack.DHTSubtype.DHT_SUBTYPE_SHT20 ||
    subtype === quack.DHTSubtype.DHT_SUBTYPE_SCD30
  ) {
    addressTypes = [quack.AddressType.ADDRESS_TYPE_I2C];
  }
  return {
    type: quack.ComponentType.COMPONENT_TYPE_DHT,
    subtypes: [
      {
        key: quack.DHTSubtype.DHT_SUBTYPE_NONE,
        value: "DHT_SUBTYPE_NONE",
        friendlyName: "DHT11"
      },
      {
        key: quack.DHTSubtype.DHT_SUBTYPE_22,
        value: "DHT_SUBTYPE_22",
        friendlyName: "DHT22"
      },
      {
        key: quack.DHTSubtype.DHT_SUBTYPE_SHT20,
        value: "DHT_SUBTYPE_SHT20",
        friendlyName: "SHT20"
      },
      {
        key: quack.DHTSubtype.DHT_SUBTYPE_FROG,
        value: "DHT_SUBTYPE_FROG",
        friendlyName: "BinPlenum"
      },
      {
        key: quack.DHTSubtype.DHT_SUBTYPE_SCD30,
        value: "DHT_SUBTYPE_SCD30",
        friendlyName: "SCD30 (Temp/Humidity)"
      }
    ],
    friendlyName: "DHT",
    description: "Measures the temperature and humidity",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: addressTypes,
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(temperature, humidity),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, temperature, humidity);
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? TemperatureHumidityDarkIcon : TemperatureHumidityLightIcon;
    }
  };
}
