import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Calcium(subtype: number = 0): ComponentTypeExtension {
  let calcium = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPB,
    quack.ComponentType.COMPONENT_TYPE_CALCIUM,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_CALCIUM,
    subtypes: [],
    friendlyName: "Calcium",
    description: "Measures concentration of calcium",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(calcium),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, calcium);
    },
    minMeasurementPeriodMs: 1000
  };
}
