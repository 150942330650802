import TemperatureHumidityDarkIcon from "assets/components/temperatureHumidityDark.png";
import TemperatureHumidityLightIcon from "assets/components/temperatureHumidityLight.png";
import {
  ComponentTypeExtension,
  simpleMeasurements,
  simpleSummaries,
  Summary
} from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { quack } from "protobuf-ts/quack";

export function Sen5x(subtype: number = 0): ComponentTypeExtension {
  let temperature = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_TEMPERATURE,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let humidity = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PERCENT,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let dust1UG = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_DUST_1UG,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let dust2UG = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_DUST_2UG,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let dust4UG = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_DUST_4UG,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let dust10UG = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_DUST_10UG,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let voc = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_VOC_INDEX,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  let nox = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_NOX_INDEX,
    quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_SEN5X,
    subtypes: [],
    friendlyName: "Sen5X",
    description: "Measures the temperature, humidity, and dust particles",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(
      temperature,
      humidity,
      dust1UG,
      dust2UG,
      dust4UG,
      dust10UG,
      voc,
      nox
    ),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(
        measurement,
        temperature,
        humidity,
        dust1UG,
        dust2UG,
        dust4UG,
        dust10UG,
        voc,
        nox
      );
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? TemperatureHumidityDarkIcon : TemperatureHumidityLightIcon;
    }
  };
}
