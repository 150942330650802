import { AxiosResponse } from "axios";
import { Scope, Team } from "models";
import { useHTTP } from "hooks";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { or } from "utils/types";
import { pondURL } from "./pond";

export interface ITeamAPIContext {
  addTeam: (team: pond.TeamSettings) => Promise<AxiosResponse<pond.AddTeamResponse>>;
  getTeam: (teamID: string, scope?: Scope) => Promise<Team>;
  updateTeam: (
    key: string,
    team: pond.TeamSettings,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.UpdateTeamResponse>>;
  updatePreferences: (key: string, preferences: pond.UserPreferences) => Promise<any>;
  listTeams: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => Promise<AxiosResponse<pond.ListTeamsResponse>>;
  listAllTeams: () => Promise<AxiosResponse<pond.ListTeamsResponse>>;
  listObjectTeams: (scope: Scope, as?: string) => Promise<any>;
  removeTeam: (key: string) => Promise<AxiosResponse<pond.RemoveTeamResponse>>;
}

export const TeamAPIContext = createContext<ITeamAPIContext>({} as ITeamAPIContext);

interface Props {}

export default function TeamProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  //const [{ team }] = useGlobalState();

  const addTeam = (team: pond.TeamSettings) => {
    return post<pond.AddTeamResponse>(pondURL("/team"), team);
  };

  const getTeam = (id: string, scope?: Scope): Promise<Team> => {
    let partial = "/team/" + id;
    if (scope) {
      partial += "?kind=" + scope.kind + "&key=" + scope.key;
    }
    return new Promise(function(resolve, reject) {
      get(pondURL(partial))
        .then((response: any) => resolve(Team.any(response.data)))
        .catch((error: any) => reject(error));
    });
  };

  const removeTeam = (key: string) => {
    return del<pond.RemoveTeamResponse>(pondURL("/teams/" + key));
  };

  const updateTeam = (key: string, team: pond.TeamSettings, asRoot?: boolean) => {
    return put<pond.UpdateBinYardResponse>(
      pondURL("/teams/" + key + (asRoot ? "?asRoot=" + asRoot.toString() : "")),
      team
    );
  };

  const updatePreferences = (key: string, preferences: pond.UserPreferences) => {
    return put(pondURL("/teams/" + key + "/preferences"), preferences);
  };

  const listTeams = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    as?: string
  ) => {
    //let asText = team ? "&as=" + team.key() : "";
    return get<pond.ListTeamsResponse>(
      pondURL(
        "/teams" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (search ? "&search=" + search : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (as ? "&as=" + as : "")
      )
    );
  };

  const listAllTeams = () => {
    //let asText = team ? "&as=" + team.key() : "";
    return get<pond.ListTeamsResponse>(pondURL("/allTeams"));
  };

  const listObjectTeams = (scope: Scope, as?: string) => {
    if (as) {
      return get(pondURL(`/${scope.kind}s/${scope.key}/teams?as=${as}`));
    }
    return get(pondURL("/" + scope.kind + "s/" + scope.key + "/teams"));
  };

  return (
    <TeamAPIContext.Provider
      value={{
        addTeam,
        getTeam,
        updateTeam,
        updatePreferences,
        listTeams,
        listObjectTeams,
        removeTeam,
        listAllTeams
      }}>
      {children}
    </TeamAPIContext.Provider>
  );
}

export const useTeamAPI = () => useContext(TeamAPIContext);
