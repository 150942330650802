import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Ethylene(subtype: number = 0): ComponentTypeExtension {
  let ethylene = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPB,
    quack.ComponentType.COMPONENT_TYPE_ETHYLENE,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_ETHYLENE,
    subtypes: [],
    friendlyName: "Ethylene",
    description: "Measures the parts per billion of ethylene",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(ethylene),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, ethylene);
    },
    minMeasurementPeriodMs: 1000
  };
}
