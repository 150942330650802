import { useHTTP } from "hooks";
import { useWebsocket } from "websocket";
import { pond } from "protobuf-ts/pond";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { dateRange } from "providers/http";
import { getComponentIDString } from "pbHelpers/Component";
import { Component } from "models";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";
import { quack } from "protobuf-ts/quack";

export interface IComponentAPIContext {
  newCron: (
    device: number,
    componentId: string,
    time: string,
    url: string,
    componentKey: string
  ) => Promise<any>;
  add: (device: number, settings: pond.ComponentSettings) => Promise<any>;
  addMultiComponents: (
    device: number,
    components: pond.MultiComponentSettings
  ) => Promise<AxiosResponse<pond.AddMultiComponentsResponse>>;
  update: (
    device: number,
    settings: pond.ComponentSettings,
    keys?: string[],
    types?: string[]
  ) => Promise<any>;
  remove: (device: number, component: string, keys?: string[], types?: string[]) => Promise<any>;
  get: (device: number, component: string, keys?: string[], types?: string[]) => Promise<any>;
  list: (
    device: number | string,
    demo?: boolean,
    keys?: string[],
    types?: string[],
    comprehensive?: boolean
  ) => Promise<AxiosResponse<pond.ListComponentsResponse>>;
  listForObject: (
    keys: string[],
    types: string[]
  ) => Promise<AxiosResponse<pond.ListComponentsResponse>>;
  listComponentCardData: (
    device: number | string,
    demo?: boolean,
    keys?: string[],
    types?: string[],
    comprehensive?: boolean
  ) => Promise<AxiosResponse<pond.ListComponentCardDataResponse>>;
  listHistory: (
    deviceId: string | number,
    componentKey: string,
    limit: number,
    offset: number,
    keys?: string[],
    types?: string[]
  ) => Promise<AxiosResponse<pond.ListComponentHistoryResponse>>;
  listMeasurements: (
    device: number,
    component: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    orderBy: string,
    demo?: boolean,
    keys?: string[],
    types?: string[]
  ) => //exportMeasurements?: boolean
  Promise<any>;
  sampleMeasurements: (
    device: number | string,
    component: string,
    startDate: any,
    endDate: any,
    sampleSize: number,
    demo?: boolean,
    keys?: string[],
    types?: string[]
  ) => Promise<any>;
  sampleUnitMeasurements: (
    device: number | string,
    component: string,
    startDate: any,
    endDate: any,
    sampleSize: number,
    demo?: boolean,
    keys?: string[],
    types?: string[],
    showErrors?: boolean
  ) => Promise<AxiosResponse<pond.SampleUnitMeasurementsResponse>>;
  updateComponentPreferences: (
    id: number | string,
    component: string,
    preferences: pond.UserPreferences,
    keys?: string[],
    types?: string[]
  ) => Promise<any>;
  listUnitMeasurements: (
    device: number,
    component: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    measurementType?: number | quack.MeasurementType,
    keys?: string[],
    types?: string[],
    showErrors?: boolean
  ) => Promise<AxiosResponse<pond.ListUnitMeasurementsResponse>>;
}

export const ComponentAPIContext = createContext<IComponentAPIContext>({} as IComponentAPIContext);

interface Props {}

export default function ComponentProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();
  const [{ as }] = useGlobalState();

  const newCron = (
    device: number,
    componentId: string,
    time: string,
    url: string,
    componentKey: string
  ) => {
    return post(
      pondURL(
        "/cron/components/arcgis?device=" +
          device +
          "&componentId=" +
          componentId +
          "&componentKey=" +
          componentKey +
          "&time=" +
          time +
          "&urlEndpoint=" +
          url
      )
    );
  };

  const addComponent = (device: number, settings: pond.ComponentSettings) => {
    if (as) return post(pondURL(`/devices/${device}/components?as=${as}`), settings);
    const url = pondURL("/devices/" + device + "/components?");
    return post(url, settings);
  };

  const addMultiComponents = (device: number, components: pond.MultiComponentSettings) => {
    if (as)
      return post<pond.AddMultiComponentsResponse>(
        pondURL(`/devices/${device}/multiComponents?as=${as}`),
        components
      );
    const url = pondURL("/devices/" + device + "/multiComponents?");
    return post<pond.AddMultiComponentsResponse>(url, components);
  };

  const updateComponent = (
    device: number,
    settings: pond.ComponentSettings,
    keys?: string[],
    types?: string[]
  ) => {
    let k: string[] = keys ? keys : [];
    if (!k.includes(device.toString())) k.push(device.toString()); //if the device id is already in the keys do not add it again
    let t: string[] = types ? types : [];
    if (!t.includes("device")) t.push("device"); // if "device" is already in the types do not add it again
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        getComponentIDString(Component.any({ settings })) +
        "/update" +
        ("?keys=" + k) +
        ("&types=" + t) +
        (as ? "&as=" + as : "")
    );
    return put(url, settings);
  };

  const removeComponent = (
    device: number,
    component: string,
    keys?: string[],
    types?: string[]
  ) => {
    let k: string[] = keys ? keys : [];
    if (!k.includes(device.toString())) k.push(device.toString()); //if the device id is already in the keys do not add it again
    let t: string[] = types ? types : [];
    if (!t.includes("device")) t.push("device"); // if "device" is already in the types do not add it again
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        ("?keys=" + k) +
        ("&types=" + t) +
        (as ? "&as=" + as : "")
    );
    return del(url);
  };

  const getComponent = (device: number, component: string, keys?: string[], types?: string[]) => {
    let k: string[] = keys ? keys : [];
    if (!k.includes(device.toString())) k.push(device.toString()); //if the device id is already in the keys do not add it again
    let t: string[] = types ? types : [];
    if (!t.includes("device")) t.push("device"); // if "device" is already in the types do not add it again
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        ("?keys=" + k) +
        ("&types=" + t) +
        (as ? "&as=" + as : "")
    );
    return get(url);
  };

  const listComponents = (
    device: number | string,
    demo: boolean = false,
    keys?: string[],
    types?: string[],
    comprehensive?: boolean
  ) => {
    let k = keys ? keys : [];
    let t = types ? types : [];
    let url = pondURL(
      "/devices/" +
        device +
        "/components?keys=" +
        k +
        "&types=" +
        t +
        (comprehensive ? "&comprehensive=" + comprehensive.toString() : ""),
      demo
    );
    if (as)
      url = pondURL(
        `/devices/${device}/components?as=${as}&keys=${k}&types=${t}` +
          (comprehensive ? "&comprehensive=" + comprehensive.toString() : ""),
        demo
      );
    return get<pond.ListComponentsResponse>(url);
  };

  const listComponentsForObject = (keys: string[], types: string[]) => {
    return get<pond.ListComponentsResponse>(
      pondURL("/components/forObject?keys=" + keys + "&types=" + types + (as ? "&as=" + as : ""))
    );
  };

  const listComponentCardData = (
    device: number | string,
    demo: boolean = false,
    keys?: string[],
    types?: string[],
    comprehensive = false
  ) => {
    let url = pondURL(
      "/devices/" +
        device +
        "/componentCards" +
        "?comprehensive=" +
        comprehensive +
        (keys ? "&keys=" + keys : "&keys=" + [device.toString()]) +
        (types ? "&types=" + types : "&types=" + ["device"]),

      demo
    );
    if (as)
      url = pondURL(
        `/devices/${device}/componentCards?as=${as}` +
          (keys ? "&keys=" + keys : "&keys=" + [device.toString()]) +
          (types ? "&types=" + types : "&types=" + ["device"]),
        demo
      );
    return get<pond.ListComponentCardDataResponse>(url);
  };

  const listHistory = (
    deviceId: string | number,
    componentKey: string,
    limit: number,
    offset: number,
    keys?: string[],
    types?: string[]
  ) => {
    return get<pond.ListComponentHistoryResponse>(
      pondURL(
        "/devices/" +
          deviceId +
          "/components/" +
          componentKey +
          "/history?limit=" +
          limit +
          "&offset=" +
          offset +
          (keys ? "&keys=" + keys : "&keys=" + [deviceId.toString()]) +
          (types ? "&types=" + types : "&types=" + ["device"])
      )
    );
  };

  const listMeasurements = (
    device: number,
    component: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    orderBy: string,
    demo: boolean = false,
    keys?: string[],
    types?: string[]
    // exportMeasurements: boolean = false
  ) => {
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        "/measurements" +
        //(exportMeasurements ? "/export" : "") +
        dateRange(startDate, endDate) +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&order=" +
        order +
        "&by=" +
        orderBy +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "&keys=" + [device]) +
        (types ? "&types=" + types : "&types=" + ["device"]),
      demo
    );
    return get(url);
  };

  const sampleMeasurements = (
    device: number | string,
    component: string,
    startDate: any,
    endDate: any,
    sampleSize: number,
    demo: boolean = false,
    keys?: string[],
    types?: string[]
  ) => {
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        "/measurements/sample" +
        dateRange(startDate, endDate) +
        "&size=" +
        sampleSize +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "&keys=" + [device]) +
        (types ? "&types=" + types : "&types=" + ["device"]),
      demo
    );
    return get(url);
  };

  const sampleUnitMeasurements = (
    device: number | string,
    component: string,
    startDate: any,
    endDate: any,
    sampleSize: number,
    demo: boolean = false,
    keys?: string[],
    types?: string[],
    showErrors: boolean = true
  ) => {
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        "/measurements/sampleUnit" +
        dateRange(startDate, endDate) +
        "&size=" +
        sampleSize +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "&keys=" + [device]) +
        (types ? "&types=" + types : "&types=" + ["device"]) +
        (showErrors ? "&showErrors=true" : "&showErrors=false"),
      demo
    );
    return get<pond.SampleUnitMeasurementsResponse>(url);
  };

  const updateComponentPreferences = (
    id: number | string,
    component: string,
    preferences: pond.UserPreferences,
    keys?: string[],
    types?: string[]
  ) => {
    const url = pondURL(
      "/devices/" +
        id +
        "/components/" +
        component +
        "/preferences" +
        (keys ? "?keys=" + keys : "?keys=" + [id.toString()]) +
        (types ? "&types=" + types : "&types=" + ["device"])
    );
    return put(url, preferences);
  };

  const listUnitMeasurements = (
    device: number,
    component: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    measurementType?: number | quack.MeasurementType,
    keys?: string[],
    types?: string[],
    showErrors?: boolean
  ) => {
    const url = pondURL(
      "/devices/" +
        device +
        "/components/" +
        component +
        "/unitMeasurements" +
        dateRange(startDate, endDate) +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&order=" +
        order +
        (measurementType ? "&type=" + measurementType : "") +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "&keys=" + [device]) +
        (types ? "&types=" + types : "&types=" + ["device"]) +
        (showErrors ? "&showErrors=true" : "&showErrors=false")
    );
    return get<pond.ListUnitMeasurementsResponse>(url);
  };

  return (
    <ComponentAPIContext.Provider
      value={{
        newCron,
        add: addComponent,
        addMultiComponents,
        update: updateComponent,
        remove: removeComponent,
        get: getComponent,
        list: listComponents,
        listForObject: listComponentsForObject,
        listComponentCardData: listComponentCardData,
        listHistory,
        listMeasurements: listMeasurements,
        sampleMeasurements: sampleMeasurements,
        sampleUnitMeasurements,
        updateComponentPreferences: updateComponentPreferences,
        listUnitMeasurements
      }}>
      {children}
    </ComponentAPIContext.Provider>
  );
}

export const useComponentAPI = () => useContext(ComponentAPIContext);

export const useComponentWebsocket = (
  device: number | string,
  component: string,
  emitter: (m: any) => void,
  rate: number = 0
) =>
  useWebsocket(
    "/devices/" + device + "/components/" + component,
    m => Component.any(JSON.parse(m.data)),
    emitter,
    rate
  );

export const useComponentsWebsocket = (
  device: number | string,
  emitter: (c: Component) => void,
  rate: number = 0
) => {
  useWebsocket(
    "/devices/" + device + "/components",
    m => Component.any(JSON.parse(m.data)),
    emitter,
    rate
  );
};

export const useMeasurementsWebsocket = (
  device: number | string,
  component: string,
  emitter: (m: any) => void,
  rate: number = 0,
  keys?: string[],
  types?: string[]
) => {
  useWebsocket(
    "/devices/" + device + "/components/" + component + "/measurements",
    m => pond.Measurement.fromObject(JSON.parse(m.data)),
    emitter,
    rate,
    keys,
    types
  );
};
