import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function PH(subtype: number = 0): ComponentTypeExtension {
  let ph = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PH,
    quack.ComponentType.COMPONENT_TYPE_PH,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_PH,
    subtypes: [],
    friendlyName: "pH",
    description: "Measures pH",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C_WITH_CHANNELS],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(ph),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, ph);
    },
    minMeasurementPeriodMs: 1000
  };
}
