import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Tag, User, Firmware, Team } from "models";
import moment from "moment";
import { useGlobalState, useStripeAPI } from "providers";
import { useAuth, useFirmwareAPI, useTagAPI, useUserAPI } from "hooks";
import { setTemperatureUnit, setPressureUnit, setDistanceUnit, setGrainUnit } from "utils";
import { pond } from "protobuf-ts/pond";
import { useHistory } from "react-router";
import { register } from "services/serviceWorker";
import { AxiosResponse } from "axios";

interface Props {
  teams: Team[];
  setTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}

export default function BackgroundTasks(props: Props) {
  const { userID, isAuthenticated } = useAuth();
  const { setTeams } = props;
  const firmwareAPI = useFirmwareAPI();
  const tagAPI = useTagAPI();
  const userAPI = useUserAPI();
  const stripeAPI = useStripeAPI();
  const [global, dispatch] = useGlobalState();
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const unlisten = history.listen(() => {
    register(true);
  });

  const handleUserResponse = useCallback(
    (response: AxiosResponse<pond.GetUserWithTeamResponse>) => {
      let u = User.any(response.data.user);
      let t = Team.create();
      if (response.data.team) {
        t = Team.any(response.data.team);
      }
      let teams: Team[] = [];
      if (response.data.teams)
        response.data.teams.forEach(team => {
          teams.push(Team.create(team));
        });
      setTeams(teams);
      if (!u.empty() && u.settings !== pond.UserSettings.create() && !u.settings.timezone) {
        u.settings.timezone = moment.tz.guess();
        userAPI.updateUser(userID, u.protobuf());
      }
      if (u.hasFeature("billing") && u.settings.stripeKey.length < 1) {
        stripeAPI.newCustomer().then((resp: any) => {
          if (resp.data["settings"] && resp.data["settings"]["stripeKey"]) {
            u.settings.stripeKey = resp.data["settings"]["stripeKey"];
          }
        });
      }
      if (u.settings.defaultTeam && u.settings.useTeam) {
        dispatch({ key: "as", value: t.id() });
      }
      dispatch({ key: "userTeamPermissions", value: u.permissions });
      dispatch({ key: "user", value: u });
      dispatch({ key: "team", value: t });
      Sentry.configureScope((scope: any) => {
        scope.setUser({
          id: userID,
          email: u.settings.email,
          username: u.name()
        });
      });
      setTemperatureUnit(u.settings.temperatureUnit);
      setPressureUnit(u.settings.pressureUnit);
      setDistanceUnit(u.settings.distanceUnit);
      setGrainUnit(u.settings.grainUnit);
    },
    [userID, dispatch, userAPI, setTeams, stripeAPI]
  );

  const handleTagResponse = useCallback(
    (response: any) => {
      let tags: Tag[] = [];
      if (response && response.data && response.data.tags) {
        response.data.tags.forEach((raw: any) => tags.push(Tag.any(raw)));
      }
      dispatch({ key: "tags", value: tags });
    },
    [dispatch]
  );

  const handleFirmwareResponse = useCallback(
    (response: any) => {
      let versions: Map<string, Firmware> = new Map();
      if (response && response.data && response.data.firmware) {
        response.data.firmware.forEach((raw: any) => {
          let firmware = Firmware.any(raw);
          let key = firmware.settings.platform + ":" + firmware.settings.channel;
          versions.set(key, firmware);
        });
      }
      dispatch({ key: "firmware", value: versions });
    },
    [dispatch]
  );

  const loadAll = useCallback(() => {
    setLoading(true);
    let getUser = userAPI.getUserWithTeam(userID);
    let listTags = tagAPI.listTags();
    let getLatestFirmware = firmwareAPI.getAllLatestFirmware();
    Promise.all([getUser, listTags, getLatestFirmware])
      .then(responses => {
        handleUserResponse(responses[0]);
        handleTagResponse(responses[1]);
        handleFirmwareResponse(responses[2]);
      })
      .finally(() => {
        setInitialized(true);
        setLoading(false);
        dispatch({ key: "backgroundTasksComplete", value: true });
      });
  }, [
    userID,
    firmwareAPI,
    tagAPI,
    userAPI,
    handleUserResponse,
    handleTagResponse,
    handleFirmwareResponse,
    dispatch
  ]);

  const runTasks = useCallback(() => {
    if (isAuthenticated && !initialized && !loading) {
      loadAll();
    }
  }, [isAuthenticated, loadAll, initialized, loading]);

  useEffect(() => {
    runTasks();

    const interval = setInterval(() => {
      runTasks();
    }, 30000);

    return () => {
      unlisten();
      clearInterval(interval);
    };
  }, [global, runTasks, unlisten]);

  return null;
}
