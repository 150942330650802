import { ConfigurablePin } from "pbHelpers/AddressTypes";
import { DeviceAvailabilityMap, DevicePositions } from "pbHelpers/DeviceAvailability";
import { quack } from "protobuf-ts/pond";

const BindaptPlusPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 512, label: "C1" }
];

export const BindaptPlusAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptPlusPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptPlusModPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 16, label: "3" },
  { address: 512, label: "C1" }
];

export const BindaptPlusModAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptPlusModPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      //[quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptPlusProPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 16, label: "3" },
  { address: 512, label: "C1" },
  { address: 1024, label: "C2" }
];

export const BindaptPlusProAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptPlusProPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptPlusProModPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 16, label: "3" },
  { address: 32, label: "4" },
  { address: 512, label: "C1" },
  { address: 1024, label: "C2" }
];

export const BindaptPlusProModAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptPlusProModPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      //[quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptPlusLitePins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" }
];

export const BindaptPlusLiteAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptPlusLitePins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptMiniPins: ConfigurablePin[] = [
  { address: 256, label: "1" },
  { address: 512, label: "2" }
];

export const BindaptMiniAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptMiniPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([[quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]]])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BindaptUltimatePins: ConfigurablePin[] = [
  { address: 1, label: "P1" },
  { address: 4, label: "P2" },
  { address: 8, label: "P3" },
  { address: 8192, label: "P4" },
  { address: 16, label: "P5" },
  //{ address: 32, label: "P6"}, //for possible expansion if we want a sixth input port on the box
  { address: 128, label: "C1" },
  { address: 256, label: "C2" },
  { address: 512, label: "C3" },
  { address: 1024, label: "C4" }
  //{ address: 2048, label: "C5"} //for possible expansion if we want a fifth control port on the box
];

export const BindaptUltimateAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BindaptUltimatePins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

const BinMonitorPins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" }
];

export const BinMonitorAvailability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, BinMonitorPins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_VAPOUR_PRESSURE_DEFICIT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_LIDAR, [0x62]],
      [quack.ComponentType.COMPONENT_TYPE_CO2, [0x61]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);
