import { Box, List, ListItem, Typography } from "@material-ui/core";
import EndpointDisplay, { EndPointData } from "apiDocs/endpointDisplay";
import React from "react";

/**
 * endpoint template
        {
            requestType: "",
            url: "https://api.brandxtech.ca/v1/",
            description: "",
            urlOptions: [],
            urlParams: [],
            requestBody: "",
            responseParams: []
        }, 
 */

export default function AgricultureEndpoints() {
  //note any requests that are not a get are commented out as our backend only allows get requests through the API for the moment
  //if you are here to put the other requests types into the api docs make sure to finish the documentation for each endpoint

  const fieldEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/fields",
      description: "get a list of fields",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated Field fields", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/field/:field",
      description: "get a specified field",
      urlParams: [":field - the key of the field"],
      responseParams: [
        "FieldSettings settings",
        "FieldStatus status",
        "repeated Permission field_permissions"
      ]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/fields/:field",
    //     description: "add a new field",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/fields/:field",
    //     description: "update an existing field",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/fields/:field",
    //     description: "remove an existing field",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const binEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/bins",
      description: "get a list of bins",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated Bin bins", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/binsAndData",
      description: "list bins and their metrics",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: [
        "repeated Bin bins",
        "repeated BinYard bin_yards",
        "BinMetrics metrics",
        "uint32 next_offset",
        "uint32 total",
        "repeated GrainBag bags"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/bins/:bin",
      description: "gets a specified bin",
      urlParams: [":bin - the key of the bin"],
      responseParams: [
        "BinSettings settings",
        "BinStatus status",
        "repeated Permission bin_permissions"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/bins/:bin/components",
      description: "list the components linked to a bin",
      urlParams: [":bin - the key of the bin"],
      responseParams: ["repeated Component components", "map<string, uint64> component_devices"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/bins/:bin/components/measurements",
      description: "get the measurements for components attached to a bin over a set interval",
      urlOptions: ["start - RFC3339 formatted timestamp", "end - RFC3339 formatted timestamp"],
      urlParams: [":bin - the key of the bin"],
      responseParams: ["repeated UnitMeasurementsForComponent measurements"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/bins/:bin/history",
      description: "get the history of changes to a bin",
      urlOptions: [
        "limit - the number of records to retrieve",
        "offset - the number of records to skip over before startgin retrieval"
      ],
      urlParams: [":bin - the key of the bin"],
      responseParams: ["repeated BinHistory history", "uint32 next_offset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/bins",
    //     description: "add a new bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin/addComponent/:device/:component",
    //     description: "attach an existing component on a device to a bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin/removeComponent/:device/:component",
    //     description: "",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin/removeAllComponents",
    //     description: "removes all attached components from a bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin/updateTopNodes",
    //     description: "updates the top nodes of cable components ttached to a bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin",
    //     description: "update a bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/bins/:bin",
    //     description: "removes an existing bin",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const binYardEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/binYards",
      description: "get a list of bin yards",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated BinYard yard", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/binYards/:binYard",
      description: "get a specific bin yard",
      urlParams: [":binYard - the key for the yard"],
      responseParams: ["BinYard yard"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/binYards",
    //     description: "add a new bin yard",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/binyards/:binyard",
    //     description: "update an existing binyard",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/binyards/:binyard",
    //     description: "delete an existing bin yard",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const harvestPlanEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/harvestplans",
      description: "get a list of harvest plans",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated HarvestPlan harvest_plan", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/harvestplans/:harvestplan",
      description: "get a specified harvest plan",
      urlParams: [":harvestplan - the key of the harvest plan"],
      responseParams: [
        "HarvestPlanSettings settings",
        "HarvestPlanStatus status",
        "repeated Permission plan_permissions"
      ]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/harvestplans/:harvestplan/history",
      description: "get a history of changes to the harvest plan",
      urlOptions: [
        "limit - the number to load",
        "offset - the number to pass over before starting to load"
      ],
      urlParams: [":harvestplan - the key of the harvest plan"],
      responseParams: ["repeated HarvestPlanHistory history", "uint32 next_offset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/harvestplans",
    //     description: "create a new harvest plan",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];
  const contractEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/contracts",
      description: "get a list of contracts",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated Contract contracts", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/contracts/:contract",
      description: "get a specified contract",
      urlParams: [":contract - the key of the contract"],
      responseParams: ["Contract contract"]
    },
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/contracts",
    //     description: "add a new contract",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/contracts/:contract",
    //     description: "update an existing contract",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/contracts/:contract",
    //     description: "remove an existing contract",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/contractsbyyear",
      description: "get a list of contracts for a specified year",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data",
        "year - the year for the contracts"
      ],
      responseParams: [
        "repeated Contract contracts",
        "uint32 next_offset",
        "uint32 total",
        "map<string, EvaluatePermissionsResponse> contract_permissions"
      ]
    }
  ];
  const grainBagEndpoints: EndPointData[] = [
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/grainbags",
      description: "get a list of grainbags",
      urlOptions: [
        "limit - the number to load",
        "offset - how many to skip over before starting to load",
        "order - the sort order",
        "by - what to sort by in the data",
        "search - a string to search for a match in the data"
      ],
      responseParams: ["repeated GrainBag grain_bags", "uint32 next_offset", "uint32 total"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/grainbags/:grainbag",
      description: "get a specified grainbag",
      urlParams: [":grainbag - the key of the grain bag"],
      responseParams: ["GrainBag grain_bag"]
    },
    {
      requestType: "GET",
      url: "https://api.brandxtech.ca/v1/grainbags/:grainbag/history",
      description: "get the history of changes to a grain bag",
      urlOptions: [
        "limit - the number of records to load",
        "offset - the number of records to skip over before starting to load",
        "start - RFC3339 formatted timestamp",
        "end - RFC3339 formatted timestamp"
      ],
      urlParams: [":grainbag - the key of the grainbag"],
      responseParams: ["repeated ObjectHistory history", "uint32 next_offset", "uint32 total"]
    }
    // {
    //     requestType: "POST",
    //     url: "https://api.brandxtech.ca/v1/grainbags",
    //     description: "add a new grain bag",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "PUT",
    //     url: "https://api.brandxtech.ca/v1/grainbags/:grainbag",
    //     description: "update an existing grain bag",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
    // {
    //     requestType: "DEL",
    //     url: "https://api.brandxtech.ca/v1/grainbags/:grainbag",
    //     description: "delete an existing grain bag",
    //     urlOptions: [],
    //     urlParams: [],
    //     requestBody: "",
    //     responseParams: []
    // },
  ];

  return (
    <Box>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving field data
      </Typography>
      <List>
        {fieldEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving bin data
      </Typography>
      <List>
        {binEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving bin yard data
      </Typography>
      <List>
        {binYardEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving harvest plan data
      </Typography>
      <List>
        {harvestPlanEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving contract data
      </Typography>
      <List>
        {contractEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h5" style={{ fontWeight: 650 }}>
        Endpoints for retrieving grain bag data
      </Typography>
      <List>
        {grainBagEndpoints.map((e, i) => (
          <ListItem key={i} style={{ marginBottom: 10 }}>
            <EndpointDisplay endpoint={e} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
