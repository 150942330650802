import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PageContainer from "./PageContainer";
import { TabContext, TabPanel } from "@material-ui/lab";
import GeneralEndpoints from "apiDocs/endpoints/generalEndpoinst";
import { IsAdaptiveAgriculture, IsAdCon, IsMiVent, IsOmniAir } from "services/whiteLabel";
import AviationEndpoints from "apiDocs/endpoints/aviationEndpoints";
import AgricultureEndpoints from "apiDocs/endpoints/agricultureendpoints";
import MiningEndpoints from "apiDocs/endpoints/miningEndpoints";
import ConstructionEndpoints from "apiDocs/endpoints/constructionEndpoints";

export default function APIDocs() {
  const [currentTab, setCurrentTab] = useState("general");
  return (
    <PageContainer>
      <Box padding={2}>
        <Typography variant="h3" style={{ fontWeight: 650 }}>
          API Documentation
        </Typography>
        <Typography>
          In order to use the API you will need to make sure to have a team key set and included in
          the header in the format key: Authorization, value: Apikey aaabbb for example
          "Authorization: Apikey fekssxi1n5gh1"
        </Typography>
      </Box>
      <TabContext value={currentTab}>
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label={"General"} value={"general"} />
          {IsAdaptiveAgriculture() && <Tab label={"Agriculture"} value={"agriculture"} />}
          {IsOmniAir() && <Tab label={"Aviation"} value={"aviation"} />}
          {IsMiVent() && <Tab label={"Mining"} value={"mining"} />}
          {IsAdCon() && <Tab label={"Construction"} value={"construction"} />}
        </Tabs>
        <TabPanel value="general">
          <GeneralEndpoints />
        </TabPanel>
        <TabPanel value="aviation">
          <AviationEndpoints />
        </TabPanel>
        <TabPanel value="agriculture">
          <AgricultureEndpoints />
        </TabPanel>
        <TabPanel value="mining">
          <MiningEndpoints />
        </TabPanel>
        <TabPanel value="construction">
          <ConstructionEndpoints />
        </TabPanel>
      </TabContext>
    </PageContainer>
  );
}
