import { Box, CardActionArea, Chip, useTheme } from "@material-ui/core";
import { GrainColour, grainName } from "grain";
import { pond } from "protobuf-ts/pond";
import React from "react";
import { Label, Legend, Pie, PieChart, ResponsiveContainer, Text } from "recharts";

interface Props {
  bushelAmount: number;
  bushelCapacity: number;
  grain: pond.Grain;
  customLabel?: string;
  customColour?: string;
  onClick: () => void;
  grainActive?: boolean;
  customUnit?: string;
}

export default function BinUtilizationChart(props: Props) {
  const {
    bushelAmount,
    bushelCapacity,
    grain,
    onClick,
    grainActive,
    customLabel,
    customColour,
    customUnit
  } = props;
  const grainLabel = customLabel ? customLabel : grainName(grain);
  const theme = useTheme();
  const grainColour = customColour ? customColour : GrainColour(grain);
  return (
    <Box height={1} width={1}>
      <CardActionArea
        onClick={onClick}
        style={{ height: "100%", borderRadius: theme.shape.borderRadius }}>
        <ResponsiveContainer>
          <PieChart onClick={onClick}>
            <Legend
              verticalAlign="bottom"
              content={() => (
                <Box textAlign="center" marginY={0.5}>
                  <Chip
                    label={grainLabel}
                    variant={grainActive === true ? "default" : "outlined"}
                  />
                </Box>
              )}
            />
            <Pie
              dataKey="value"
              cursor="pointer"
              isAnimationActive={false}
              data={[
                {
                  name: "Amount",
                  value: bushelAmount,
                  fill: grainColour
                },
                {
                  name: "Capacity",
                  value: bushelCapacity - bushelAmount,
                  fill: grainColour,
                  opacity: theme.palette.type === "light" ? 0.25 : 0.1
                }
              ]}
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              startAngle={270}
              endAngle={-90}
              stroke="transparent">
              <Label
                position="center"
                fill={theme.palette.text.secondary}
                fontSize={"0.725rem"}
                content={props => {
                  const { cx, cy } = props.viewBox as any;
                  return (
                    <g>
                      <Text
                        x={cx}
                        y={cy}
                        dy={-8}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        fill={props.fill}
                        fontSize={props.fontSize}>
                        {bushelAmount.toLocaleString() + (customUnit ? customUnit : " bu")}
                      </Text>
                      <Text
                        x={cx}
                        y={cy}
                        dy={0}
                        textAnchor="middle"
                        verticalAnchor="end"
                        fill={props.fill}
                        fontSize={props.fontSize}>
                        _______
                      </Text>
                      <Text
                        x={cx}
                        y={cy}
                        dy={12}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        fill={props.fill}
                        fontSize={props.fontSize}>
                        {bushelCapacity.toLocaleString() + (customUnit ? customUnit : " bu")}
                      </Text>
                    </g>
                  );
                }}
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </CardActionArea>
    </Box>
  );
}
