import LidarSensorDarkIcon from "assets/components/lidarSensorDark.png";
import LidarSensorLightIcon from "assets/components/lidarSensorLight.png";
import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { Component } from "models";

export function extractLidarCentimeters(component: Component): number {
  if (component.settings.type === quack.ComponentType.COMPONENT_TYPE_LIDAR) {
    if (component.status.lastMeasurement?.measurement?.distance?.cm) {
      return component.status.lastMeasurement?.measurement?.distance?.cm;
    }
  }
  return -1;
}

export function Lidar(subtype: number = 0): ComponentTypeExtension {
  let distance = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_DISTANCE_CM,
    quack.ComponentType.COMPONENT_TYPE_LIDAR,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_LIDAR,
    subtypes: [
      {
        key: quack.LidarSubtype.LIDAR_SUBTYPE_NONE,
        value: "LIDAR",
        friendlyName: "Lidar"
      },
      {
        key: quack.LidarSubtype.LIDAR_SUBTYPE_LONG_DISTANCE,
        value: "LIDAR_LONG_DISTANCE",
        friendlyName: "Long Distance Lidar"
      }
    ],
    friendlyName: "Lidar",
    description: "Measures the distance to a target by using light reflection",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(distance),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, distance);
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? LidarSensorDarkIcon : LidarSensorLightIcon;
    }
  };
}
