import {
  ComponentTypeExtension,
  Summary,
  Subtype,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import PressureDarkIcon from "assets/components/pressureDark.png";
import PressureLightIcon from "assets/components/pressureLight.png";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Pressure(subtype: number = 0): ComponentTypeExtension {
  let pressure = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PRESSURE,
    quack.ComponentType.COMPONENT_TYPE_PRESSURE,
    subtype
  );
  let addressTypes = [quack.AddressType.ADDRESS_TYPE_I2C];
  if (subtype === quack.PressureSubtype.PRESSURE_SUBTYPE_FROG) {
    addressTypes = [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY];
  }
  return {
    type: quack.ComponentType.COMPONENT_TYPE_PRESSURE,
    subtypes: [
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_NONE,
        value: "PRESSURE_SUBTYPE_NONE",
        friendlyName: "Absolute Pressure"
      } as Subtype,
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_GAUGE,
        value: "PRESSURE_SUBTYPE_GAUGE",
        friendlyName: "Pressure Gauge"
      } as Subtype,
      {
        key: quack.PressureSubtype.PRESSURE_SUBTYPE_FROG,
        value: "PRESSURE_SUBTYPE_FROG",
        friendlyName: "Pressure (Frog)"
      } as Subtype
    ],
    friendlyName: "Pressure",
    description: "Measures the atmospheric pressure or absolute pressure",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: addressTypes,
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(pressure),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, pressure);
    },
    minMeasurementPeriodMs: 1000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? PressureDarkIcon : PressureLightIcon;
    }
  };
}
