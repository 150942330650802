import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import ScrollMenu from "react-horizontal-scroll-menu";
import { useHistory } from "react-router";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { useMobile } from "hooks";
import { GrainBag } from "models/GrainBag";
import GrainBagCard from "./grainBagCard";

interface Props {
  grainBags: GrainBag[];
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridListTile: {
      minHeight: "184px",
      height: "auto !important",
      width: "184px",
      padding: 2
    },
    hidden: {
      visibility: "hidden"
    }
  })
);

export default function GrainBagList(props: Props) {
  const { grainBags, title } = props;
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMobile();

  const goToBag = (i: number) => {
    let path = "/grainbags/" + grainBags[i].key();
    history.replace(path);
  };

  return (
    <React.Fragment>
      {title && <Typography>{title}</Typography>}
      <ScrollMenu
        wheel={false}
        alignCenter={false}
        inertiaScrolling
        hideArrows
        hideSingleArrow
        arrowDisabledClass={classes.hidden}
        onSelect={e => {
          goToBag(e as number);
        }}
        arrowLeft={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowBack />
          </Button>
        }
        arrowRight={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowForward />
          </Button>
        }
        data={grainBags.map((b, i) => (
          <Box key={i} className={classes.gridListTile}>
            <GrainBagCard grainBag={b} />
          </Box>
        ))}
      />
    </React.Fragment>
  );
}
