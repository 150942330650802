import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function Weight(subtype: number = 0): ComponentTypeExtension {
  let weight = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_WEIGHT,
    quack.ComponentType.COMPONENT_TYPE_WEIGHT,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_WEIGHT,
    subtypes: [],
    friendlyName: "Weight",
    description: "Measures weight in grams",
    isController: false,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_INCREMENTAL],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(weight),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, weight);
    },
    minMeasurementPeriodMs: 1000
  };
}
