import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function StepperMotor(subtype: number = 0): ComponentTypeExtension {
  let motor = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_BOOLEAN,
    quack.ComponentType.COMPONENT_TYPE_STEPPER_MOTOR,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_STEPPER_MOTOR,
    subtypes: [],
    friendlyName: "Stepper Motor",
    description: "Controls a motor that turns clockwise or counterclockwise",
    isController: true,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [
      quack.InteractionResultType.INTERACTION_RESULT_TYPE_RUN,
      quack.InteractionResultType.INTERACTION_RESULT_TYPE_SET
    ],
    states: ["Stopped", "Running"],
    measurements: simpleMeasurements(motor),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, motor);
    },
    minMeasurementPeriodMs: 1000
  };
}
