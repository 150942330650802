import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { cloneDeep } from "lodash";

export class Team {
  public settings: pond.TeamSettings = pond.TeamSettings.create();
  public status: pond.TeamStatus = pond.TeamStatus.create();
  public permissions: pond.Permission[] = [];
  public preferences: pond.UserPreferences = pond.UserPreferences.create();

  public static create(pb?: pond.Team): Team {
    let my = new Team();
    if (pb) {
      my.settings = pond.TeamSettings.fromObject(cloneDeep(or(pb.settings, {})));
      my.status = pond.TeamStatus.fromObject(cloneDeep(or(pb.status, {})));
      my.permissions = cloneDeep(pb.permissions);
      my.preferences = pond.UserPreferences.fromObject(cloneDeep(or(pb.preferences, {})));
    }
    return my;
  }

  public static any(data: any): Team {
    return Team.create(pond.Team.fromObject(cloneDeep(data)));
  }

  public empty(): boolean {
    return this.key() === "";
  }

  public key(): string {
    return this.settings.key;
  }

  public id(): string {
    return this.settings.key;
  }

  public name(): string {
    return this.settings.name !== "" ? this.settings.name : "Team " + this.settings.key;
  }

  public static clone(other?: Team): Team {
    if (other) {
      return Team.create(
        pond.Team.fromObject({
          settings: pond.TeamSettings.fromObject(cloneDeep(or(other.settings, {}))),
          status: pond.TeamStatus.fromObject(cloneDeep(or(other.status, {})))
          //permissions: cloneDeep(other.permissions),
          //preferences: pond.TeamPreferences.fromObject(cloneDeep(or(other.preferences, {})))
        })
      );
    }
    return Team.create();
  }

  public protobuf(): pond.Team {
    return pond.Team.fromObject({
      settings: this.settings,
      status: this.status
    });
  }

  /*public allowedTo(action: string): boolean {
    return this.settings.actions.includes(action);
  }

  public hasFeature(flag: string): boolean {
    return this.settings.features.includes(flag);
  }*/
}
