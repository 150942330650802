import BarleyImg from "assets/grain/barley.jpg";
import CanolaImg from "assets/grain/canola.jpg";
import CornImg from "assets/grain/corn.jpg";
import FlaxImg from "assets/grain/flax.jpg";
import LentilImg from "assets/grain/lentil.jpg";
import OatImg from "assets/grain/oat.jpg";
import PeaImg from "assets/grain/pea.jpg";
import PeanutImg from "assets/grain/peanut.jpg";
import RiceImg from "assets/grain/rice.jpg";
import SorghumImg from "assets/grain/sorghum.jpg";
import SoybeanImg from "assets/grain/soybean.jpg";
import SunflowerImg from "assets/grain/sunflower.jpg";
import WheatImg from "assets/grain/wheat.jpg";
import { Option } from "common/SearchSelect";
import { cloneDeep } from "lodash";
import { pond } from "protobuf-ts/pond";
import { Equation } from "./GrainMoisture";

export interface GrainExtension {
  name: string;
  group: string;
  equation: Equation;
  a: number;
  b: number;
  c: number;
  deprecated?: boolean;
  setTempC: number; //drying temperature in degrees Celcius
  targetMC: number; //target moisture content % for grain elevators
  img?: string;
  colour: string;
  weightConversionKg: number;
  bushelsPerTonne: number;
}

const defaultSetTemp = 30.0;

const defaultGrain: GrainExtension = {
  name: "None",
  group: "",
  equation: Equation.none,
  a: 0,
  b: 0,
  c: 0,
  setTempC: defaultSetTemp,
  targetMC: 15.0,
  colour: "#424242",
  weightConversionKg: 0,
  bushelsPerTonne: 0
};

export const GrainExtensions: Map<pond.Grain, GrainExtension> = new Map([
  [pond.Grain.GRAIN_INVALID, defaultGrain],
  [pond.Grain.GRAIN_NONE, defaultGrain],
  [
    pond.Grain.GRAIN_CUSTOM,
    {
      name: "Custom Type",
      group: "",
      equation: Equation.none,
      a: 0,
      b: 0,
      c: 0,
      setTempC: defaultSetTemp,
      targetMC: 15.0,
      colour: "yellow",
      weightConversionKg: 0,
      bushelsPerTonne: 0
    }
  ],
  [
    pond.Grain.GRAIN_BARLEY,
    {
      name: "Barley",
      group: "Barley",
      equation: Equation.chungPfost,
      a: 475.12,
      b: 0.14843,
      c: 71.996,
      setTempC: 33.0,
      targetMC: 14.9,
      img: BarleyImg,
      colour: "#27632a",
      weightConversionKg: 21.772657171369,
      bushelsPerTonne: 45.93
    }
  ],
  [
    pond.Grain.GRAIN_BUCKWHEAT,
    {
      name: "Buckwheat",
      group: "Buckwheat",
      equation: Equation.chungPfost,
      a: 103540000,
      b: 0.1646,
      c: 15853000,
      deprecated: true,
      setTempC: defaultSetTemp,
      targetMC: 16.1,
      colour: "#46b298",
      weightConversionKg: 1,
      bushelsPerTonne: 45.93
    }
  ],
  [
    pond.Grain.GRAIN_CANOLA,
    {
      name: "Canola",
      group: "Canola",
      equation: Equation.halsey,
      a: 3.489,
      b: -0.010553,
      c: 1.86,
      setTempC: 38.0,
      targetMC: 10.1,
      img: CanolaImg,
      // colour: "#cddc39",
      colour: "#ffff00",
      weightConversionKg: 22.67961461,
      bushelsPerTonne: 44.092
    }
  ],
  [
    pond.Grain.GRAIN_RAPESEED,
    {
      name: "Rapeseed",
      group: "Canola",
      equation: Equation.halsey,
      a: 3.0026,
      b: -0.0048967,
      c: 1.7607,
      setTempC: 38.0,
      targetMC: 10.1,
      img: CanolaImg,
      colour: "#cddc39",
      weightConversionKg: 27.215537532,
      bushelsPerTonne: 44.092
    }
  ],
  [
    pond.Grain.GRAIN_CORN,
    {
      name: "Corn",
      group: "Corn",
      equation: Equation.henderson,
      a: 0.000066612,
      b: 1.9677,
      c: 42.143,
      setTempC: 38.0,
      targetMC: 15.6,
      img: CornImg,
      colour: "#ffef62",
      weightConversionKg: 25.4014333665971,
      bushelsPerTonne: 39.368
    }
  ],
  [
    pond.Grain.GRAIN_OATS,
    {
      name: "Oats",
      group: "Oats",
      equation: Equation.henderson,
      a: 0.000085511,
      b: 2.0087,
      c: 37.811,
      setTempC: 32.0,
      targetMC: 13.6,
      img: OatImg,
      colour: "#79955a",
      weightConversionKg: 15.4222988297197,
      bushelsPerTonne: 64.842
    }
  ],
  [
    pond.Grain.GRAIN_PEANUTS,
    {
      name: "Peanuts",
      group: "Peanuts",
      equation: Equation.oswin,
      a: 8.6588,
      b: -0.057904,
      c: 2.6204,
      setTempC: defaultSetTemp,
      targetMC: 10.5,
      img: PeanutImg,
      colour: "#b2a058",
      weightConversionKg: 23.3124,
      bushelsPerTonne: 105.263
    }
  ],
  [
    pond.Grain.GRAIN_RICE_LONG,
    {
      name: "Long Grain Rice",
      group: "Rice",
      equation: Equation.henderson,
      a: 0.000041276,
      b: 2.1191,
      c: 49.828,
      setTempC: defaultSetTemp,
      targetMC: 12,
      img: RiceImg,
      colour: "#7c9c99",
      weightConversionKg: 28.439,
      bushelsPerTonne: 49.02
    }
  ],
  [
    pond.Grain.GRAIN_RICE_MEDIUM,
    {
      name: "Medium Grain Rice",
      group: "Rice",
      equation: Equation.henderson,
      a: 0.000035502,
      b: 2.31,
      c: 27.396,
      setTempC: defaultSetTemp,
      targetMC: 12,
      img: RiceImg,
      colour: "#7c9c99",
      weightConversionKg: 29.979,
      bushelsPerTonne: 49.02
    }
  ],
  [
    pond.Grain.GRAIN_RICE_SHORT,
    {
      name: "Short Grain Rice",
      group: "Rice",
      equation: Equation.henderson,
      a: 0.000048524,
      b: 2.0794,
      c: 45.646,
      setTempC: defaultSetTemp,
      targetMC: 12,
      img: RiceImg,
      colour: "#7c9c99",
      weightConversionKg: 30.744,
      bushelsPerTonne: 49.02
    }
  ],
  [
    pond.Grain.GRAIN_SORGHUM,
    {
      name: "Sorghum",
      group: "Sorghum",
      equation: Equation.chungPfost,
      a: 797.33,
      b: 0.18159,
      c: 52.238,
      setTempC: defaultSetTemp,
      targetMC: 12.0,
      img: SorghumImg,
      colour: "#829baf",
      weightConversionKg: 25.4014333665971,
      bushelsPerTonne: 39.368
    }
  ],
  [
    pond.Grain.GRAIN_SOYBEANS,
    {
      name: "Soybeans",
      group: "Soybeans",
      equation: Equation.chungPfost,
      a: 228.2,
      b: 0.2072,
      c: 30,
      setTempC: defaultSetTemp,
      targetMC: 14.1,
      img: SoybeanImg,
      colour: "#7d6d99",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_SUNFLOWER,
    {
      name: "Sunflower",
      group: "Sunflower",
      equation: Equation.henderson,
      a: 0.00031,
      b: 1.7459,
      c: 66.603,
      setTempC: defaultSetTemp,
      targetMC: 9.6,
      img: SunflowerImg,
      colour: "#ffab40",
      weightConversionKg: 13.6079107321056,
      bushelsPerTonne: 73.487
    }
  ],
  [
    pond.Grain.GRAIN_WHEAT_DURUM,
    {
      name: "Durum Wheat",
      group: "Wheat",
      equation: Equation.oswin,
      a: 13.101,
      b: -0.052626,
      c: 2.9987,
      setTempC: 37.0,
      targetMC: 14.6,
      img: WheatImg,
      colour: "#46b298",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_WHEAT_HARD_RED,
    {
      name: "Hard Red Wheat",
      group: "Wheat",
      equation: Equation.chungPfost,
      a: 610.34,
      b: 0.15526,
      c: 93.213,
      setTempC: 37.0,
      targetMC: 14.6,
      img: WheatImg,
      colour: "#46b298",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_FLAX_UNRETTED,
    {
      name: "Un-retted Flax",
      group: "Flax",
      equation: Equation.halsey,
      a: 5.11,
      b: Math.pow(-8.46 * 10, -3),
      c: 2.26,
      setTempC: 33.0,
      targetMC: 10.1,
      img: FlaxImg,
      colour: "#6e6d19",
      weightConversionKg: 25.4014333665971,
      bushelsPerTonne: 39.368
    }
  ],
  [
    pond.Grain.GRAIN_FLAX_DEW_RETTED,
    {
      name: "Dew-retted Flax",
      group: "Flax",
      equation: Equation.oswin,
      a: 6.5,
      b: Math.pow(-1.68 * 10, -2),
      c: 3.2,
      setTempC: 33.0,
      targetMC: 10.1,
      img: FlaxImg,
      colour: "#6e6d19",
      weightConversionKg: 25.4014333665971,
      bushelsPerTonne: 39.368
    }
  ],
  [
    pond.Grain.GRAIN_YELLOW_PEAS,
    {
      name: "Yellow Peas",
      group: "Peas",
      equation: Equation.oswin,
      a: 14.81,
      b: -0.109,
      c: 3.019,
      setTempC: 32.0,
      targetMC: 16.1,
      img: PeaImg,
      colour: "#ffe100",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_LENTILS_BLAZE,
    {
      name: "Blaze Lentils",
      group: "Lentils",
      equation: Equation.halsey,
      a: 5.39,
      b: -0.015,
      c: 2.273,
      setTempC: 30.0,
      targetMC: 13.1,
      img: LentilImg,
      colour: "#cb5e3c",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_LENTILS_REDBERRY,
    {
      name: "Redberry Lentils",
      group: "Lentils",
      equation: Equation.halsey,
      a: 4.749,
      b: -0.0116,
      c: 2.066,
      setTempC: 30.0,
      targetMC: 14.1,
      img: LentilImg,
      colour: "#cb5e3c",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ],
  [
    pond.Grain.GRAIN_LENTILS_ROBIN,
    {
      name: "Robin Lentils",
      group: "Lentils",
      equation: Equation.halsey,
      a: 5.176,
      b: -0.0065,
      c: 2.337,
      setTempC: 30.0,
      targetMC: 14.1,
      img: LentilImg,
      colour: "#cb5e3c",
      weightConversionKg: 27.2158214642112,
      bushelsPerTonne: 36.744
    }
  ]
]);

export function GetGrainExtensionMap(): Map<pond.Grain, GrainExtension> {
  return cloneDeep(GrainExtensions);
}

export default function GrainDescriber(type: pond.Grain): GrainExtension {
  let describer = GrainExtensions.get(type);
  //console.log(describer)
  if (describer?.name === "None") {
    //console.trace()
  }
  return describer ? describer : defaultGrain;
}

export function grainName(type: pond.Grain): string {
  return GrainDescriber(type).name;
}

export function GrainColour(type: pond.Grain): string {
  return GrainDescriber(type).colour;
}

export const ToGrainOption = (grainType: pond.Grain): Option => {
  let grainDescriber = GrainDescriber(grainType);
  return {
    value: pond.Grain[grainType],
    label: grainDescriber.name,
    group: grainDescriber.group
  } as Option;
};

export function GrainOptions(): Option[] {
  let options: Option[] = [];
  Object.values(pond.Grain).forEach(grainType => {
    if (
      typeof grainType !== "string" &&
      grainType !== pond.Grain.GRAIN_INVALID &&
      grainType !== pond.Grain.GRAIN_CUSTOM
    ) {
      options.push(ToGrainOption(grainType));
    }
  });
  return options;
}
