import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function AirQuality(subtype: number = 0): ComponentTypeExtension {
  let tvoc = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPB,
    quack.ComponentType.COMPONENT_TYPE_AIR_QUALITY,
    subtype
  );
  let eco2 = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPM,
    quack.ComponentType.COMPONENT_TYPE_AIR_QUALITY,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_AIR_QUALITY,
    subtypes: [
      {
        key: quack.AirQualitySubtype.AIR_QUALITY_SUBTYPE_SGP30,
        value: "AIR_QUALITY_SUBTYPE_SGP30",
        friendlyName: "Air Quality (SGP30)"
      }
    ],
    friendlyName: "Air Quality",
    description: "Measures the air quality based on metal oxide elements",
    isController: false,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(tvoc, eco2),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, tvoc, eco2);
    },
    minMeasurementPeriodMs: 1000
  };
}
