import { Box, Card, IconButton, Typography } from "@material-ui/core";
import GrainDescriber from "grain/GrainDescriber";
import { cloneDeep } from "lodash";
import { Bin } from "models";
import { GrainCable } from "models/GrainCable";
import moment from "moment";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers";
import React, { useCallback, useEffect, useState } from "react";
import { getGrainUnit, or } from "utils";
//import { useHistory } from "react-router";
//import BinModeDot from "./BinModeDot";
import BinSVG from "./BinSVG";

interface Props {
  bin: Bin;
  duplicateBin: (bin: Bin) => void;
  dupHovered: (hovered: boolean) => void;
}

export default function BinCard(props: Props) {
  const { bin } = props;
  const [cables, setCables] = useState<GrainCable[]>();
  const [modeDetails, setModeDetails] = useState("");
  const [lidarPercentage, setLidarPercentage] = useState<number | undefined>();
  const [lidarBushels, setLidarBushels] = useState<number | undefined>();
  const [{ user }] = useGlobalState();

  useEffect(() => {
    let newGrainCables: GrainCable[] = [];
    if (bin.status.grainCables === undefined) return;
    //go through each of the cables flipping them to display on the bin correctly
    bin.status.grainCables.forEach(cable => {
      let c: GrainCable = new GrainCable();
      let flippedTemps = cloneDeep(cable.celcius);
      let flippedHums = cloneDeep(cable.celcius);
      c.temperatures = flippedTemps.reverse();
      c.humidities = flippedHums.reverse();
      c.topNode = cable.topNode;
      newGrainCables.push(c);
    });
    setCables(newGrainCables);
    let cm = 0;
    if (bin.status.distance && bin.status.distance > 0) {
      //note that no conversions are happening as the unit measurement model is not being used so the value will be in cm
      cm = bin.status.distance;
      let height = or(bin.settings.specs?.heightCm, 0);
      let ratio = 1 - cm / height;
      let capacity = or(bin.settings.specs?.bushelCapacity, 0);
      let lidarEstimate = Math.round(capacity * ratio);
      setLidarBushels(lidarEstimate);
      setLidarPercentage(Math.round((lidarEstimate / capacity) * 100));
    }
  }, [bin]);

  useEffect(() => {
    let now = moment();
    let duration = moment.duration(moment(bin.status.lastModeChange).diff(now));
    let days = duration.asDays();
    days = Math.abs(days);
    duration.subtract(moment.duration(days, "days"));
    let hours = duration.hours();
    hours = Math.abs(hours);

    if (days > 50 && bin.settings.mode === pond.BinMode.BIN_MODE_DRYING) {
      setModeDetails("Calculating...");
    } else if (bin.settings.mode === pond.BinMode.BIN_MODE_NONE) {
      setModeDetails("No Mode");
    } else {
      setModeDetails(Math.floor(days) + "d " + hours + "h");
    }
  }, [bin]);

  const typeDisplay = () => {
    let grainType = bin.settings.inventory?.grainType ?? pond.Grain.GRAIN_NONE;
    if (bin.storage() === pond.BinStorage.BIN_STORAGE_SUPPORTED_GRAIN) {
      return (
        <Box height={25}>
          <Typography variant="subtitle2" style={{ fontSize: "0.7rem", fontWeight: 700 }}>
            {GrainDescriber(grainType).name}
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: "0.7rem", fontWeight: 700 }}>
            {bin.settings.inventory?.grainSubtype}
          </Typography>
        </Box>
      );
    }
    return (
      <Box height={25}>
        <Typography variant="subtitle2" style={{ fontSize: "0.7rem", fontWeight: 700 }}>
          {bin.settings.inventory?.customTypeName}
        </Typography>
      </Box>
    );
  };

  const binGraphic = useCallback(() => {
    return (
      <Box display="flex" justifyContent="center" paddingTop={0}>
        <Box padding={0} style={{ flex: 1, textAlign: "left", flexDirection: "column" }}>
          {typeDisplay()}
          <Box paddingTop={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle2" style={{ fontSize: 10 }}>
              Moisture
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              {bin.status.grainMoisture}%
            </Typography>
            <Typography variant="subtitle2" style={{ fontSize: 10 }}>
              {bin.settings.mode === pond.BinMode.BIN_MODE_DRYING
                ? "Completion"
                : bin.settings.mode === pond.BinMode.BIN_MODE_NONE
                ? "Mode"
                : "In bin For"}
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              {modeDetails}
            </Typography>
          </Box>
        </Box>
        <Box padding={1} style={{ flex: 1, textAlign: "center", flexDirection: "column" }}>
          <BinSVG
            cableEstimate={bin.settings.autoGrainNode}
            height={128}
            lidarEstimate={lidarPercentage}
            binShape={bin.settings.specs?.shape}
            fillPercentage={bin.fillPercent()}
            //tempNodes={lastGrainCable?.temperatures}
            cables={cables}
            highTemp={bin.settings.highTemp}
            lowTemp={bin.settings.lowTemp}
          />
        </Box>
      </Box>
    );
  }, [bin, cables, modeDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const inventoryDisplay = (current: number, capacity: number) => {
    if (bin.storage() === pond.BinStorage.BIN_STORAGE_FERTILIZER) {
      return (
        Math.round(current * 35.239).toLocaleString() +
        "/" +
        Math.round(capacity * 35.239).toLocaleString() +
        (lidarBushels ? "(" + Math.round(lidarBushels * 35.239).toLocaleString() + " est)" : "") +
        " L"
      );
    }
    if (getGrainUnit() === pond.GrainUnit.GRAIN_UNIT_WEIGHT && bin.bushelsPerTonne() > 1) {
      return (
        bin.grainTonnes().toLocaleString() +
        (lidarBushels
          ? "(" + Math.round(lidarBushels / bin.bushelsPerTonne()).toLocaleString() + " est)"
          : "") +
        " mT " +
        bin.fillPercent() +
        "%"
      );
    }
    return (
      current.toLocaleString() +
      "/" +
      capacity.toLocaleString() +
      (lidarBushels ? "(" + lidarBushels.toLocaleString() + " est)" : "") +
      " bu"
    );
  };

  const info = () => {
    const inv = bin.settings.inventory;
    let bushelAmount = inv?.grainBushels;
    let bushelCapacity = bin.settings.specs?.bushelCapacity;
    const empty =
      !inv || inv.empty || !bushelCapacity || bushelCapacity <= 0 || inv.grainBushels <= 0;
    return (
      <Box width={1} marginTop={0}>
        <Typography align="center" color="textSecondary" noWrap style={{ fontSize: "0.65rem" }}>
          {empty || !bushelAmount || !bushelCapacity
            ? "empty"
            : inventoryDisplay(bushelAmount, bushelCapacity)}
        </Typography>
      </Box>
    );
  };

  return (
    <Card>
      {user.hasFeature("admin") && (
        <IconButton
          style={{
            height: 35,
            width: 35,
            position: "absolute",
            zIndex: 2,
            marginLeft: 145,
            marginTop: 15
          }}
          onClick={e => props.duplicateBin(bin)}
          onMouseEnter={() => props.dupHovered(true)}
          onMouseLeave={() => props.dupHovered(false)}>
          +
        </IconButton>
      )}
      {/* <Box position="absolute" top={4} right={4}>
        <BinModeDot mode={bin.settings.mode} />
      </Box> */}
      <Box padding={1}>
        <Typography
          align="left"
          variant="body1"
          color="textPrimary"
          noWrap
          style={{ fontSize: "0.8rem", fontWeight: 700 }}>
          {bin.name()}
        </Typography>
        {binGraphic()}
        {info()}
      </Box>
    </Card>
  );
}
