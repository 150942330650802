import { Box, Card, Grid, Typography } from "@material-ui/core";
import GrainDescriber from "grain/GrainDescriber";
import { GrainBag } from "models/GrainBag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GrainBagSVG from "./grainBagSVG";

interface Props {
  grainBag: GrainBag;
}

export default function GrainBagCard(props: Props) {
  const { grainBag } = props;
  const [fillLevel, setFillLevel] = useState(0);

  useEffect(() => {
    setFillLevel((grainBag.bushels() / grainBag.capacity()) * 100);
  }, [grainBag]);

  const timeDisplay = () => {
    let now = moment();
    let duration = moment.duration(moment(grainBag.settings.fillDate).diff(now));
    let days = duration.asDays();
    days = Math.abs(days);
    duration.subtract(moment.duration(days, "days"));
    // let hours = duration.hours();
    // hours = Math.abs(hours);

    return Math.floor(days) + " days";
  };

  return (
    <Card>
      <Grid container direction="row" style={{ padding: 10 }}>
        <Grid item xs={6}>
          <Typography
            align="left"
            variant="body1"
            color="textPrimary"
            noWrap
            style={{ fontSize: "0.8rem", fontWeight: 700 }}>
            {grainBag.name()}
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: "0.7rem", fontWeight: 700 }}>
            {grainBag.isCustom()
              ? grainBag.settings.customGrain
              : GrainDescriber(grainBag.settings.supportedGrain).name}
          </Typography>
          <Typography variant="subtitle2" style={{ fontSize: "0.7rem", fontWeight: 700 }}>
            {grainBag.settings.grainSubtype}
          </Typography>
          <Box style={{ position: "absolute", top: 75 }}>
            <Typography variant="subtitle2" style={{ fontSize: 10 }}>
              Days in Field
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              {timeDisplay()}
            </Typography>
          </Box>
          <Box style={{ position: "absolute", top: 115 }}>
            <Typography variant="subtitle2" style={{ fontSize: 10 }}>
              Initial Moisture
            </Typography>
            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
              {grainBag.settings.initialMoisture}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} style={{ padding: 5 }}>
          <Box>
            <GrainBagSVG fillPercent={fillLevel} height={150} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" color="textSecondary" noWrap style={{ fontSize: "0.65rem" }}>
            {grainBag.bushels() <= 0
              ? "empty"
              : grainBag.bushels().toLocaleString() +
                "/" +
                grainBag.capacity().toLocaleString() +
                " bu"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
