import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles
} from "@material-ui/core";
import { getTableIcons } from "common/ResponsiveTable";
import MaterialTable, { Column } from "material-table";
import { Bin } from "models";
import { useBinAPI, useGlobalState, useSnackbar } from "providers";
import fans from "fans/fans_client.json";
import React, { useState, useEffect } from "react";
import { getThemeType } from "theme";
import AerationFanIcon from "products/AgIcons/AerationFanIcon";
import FanPicker from "fans/fanPicker";
import ResponsiveDialog from "common/ResponsiveDialog";
import { useMobile } from "hooks";

const useStyles = makeStyles(() => {
  return createStyles({
    dark: {
      backgroundColor: getThemeType() === "light" ? "rgb(245, 245, 245)" : "rgb(50, 50, 50)",
      padding: 5
    },
    light: {
      backgroundColor: getThemeType() === "light" ? "rgb(235, 235, 235)" : "rgb(60, 60, 60)",
      padding: 5
    }
  });
});

interface jsonFan {
  id: number;
  name: string;
  kind: string;
  rpm: number;
  diameter: number;
  horsepower: number;
}

interface BinRow {
  bin: Bin;
  fan?: jsonFan;
}

interface Props {
  isLoading: boolean;
  bins: Bin[];
  getControllerInfo?: (totalControllers: number, onControllers: number) => void;
}

export default function BinsFansStatusTable(props: Props) {
  const [{ as }] = useGlobalState();
  const binAPI = useBinAPI();
  const { bins, isLoading, getControllerInfo } = props;
  const { openSnack } = useSnackbar();
  const [tableData, setTableData] = useState<BinRow[]>([]);
  const [fanMap, setFanMap] = useState<Map<number, jsonFan>>(new Map());
  const isMobile = useMobile();
  const [pageSize, setPageSize] = useState(10);
  const [openPickerDialog, setOpenPickerDialog] = useState(false);
  const [selectedBinRow, setSelectedBinRow] = useState<BinRow | undefined>();
  const [newFanID, setNewFanID] = useState<number | undefined>();
  const classes = useStyles();

  //define the columns for the desktop table
  const columns: Column<BinRow>[] = [
    {
      title: "Bin",
      field: "bin.settings.name"
    },
    {
      title: "Bin Fan",
      field: "fan.name",
      hidden: isMobile,
      render: row => {
        if (row.fan) {
          return row.fan.name;
        } else {
          return "No Fan Set on Bin";
        }
      }
    },
    {
      title: "Fan Type",
      field: "fan.kind",
      hidden: isMobile
    },
    {
      title: "Horsepower",
      field: "fan.horsepower",
      hidden: isMobile
    },
    //the controllers
    {
      title: "Fan Controllers",
      //field: "",
      render: row => {
        if (row.bin.status.fans.length > 0) {
          return (
            <Grid container direction="column" alignItems="center">
              {row.bin.status.fans.map((binFan, i) => (
                <Grid
                  key={i}
                  container
                  direction="row"
                  justify="space-between"
                  className={i % 2 === 0 ? classes.dark : classes.light}>
                  <Grid item>{binFan.name}</Grid>
                  <Grid item>{binFan.state ? "On" : "Off"}</Grid>
                </Grid>
              ))}
            </Grid>
          );
        } else {
          return "No Controllers attached to this bin";
        }
      }
    }
  ];

  useEffect(() => {
    //build the fanmap
    let fanMap: Map<number, jsonFan> = new Map();
    fans["bulk"].forEach(fan => {
      fanMap.set(fan.id, fan);
    });
    setFanMap(fanMap);

    let data: BinRow[] = [];
    let dataMap: Map<string, BinRow> = new Map();
    let totalControllers = 0;
    let onControllers = 0;
    bins.forEach(bin => {
      //put the bin in the row
      let row: BinRow = {
        bin: bin
      };
      //if the bin has a fan id set get it from the json and put it in the row data
      if (bin.fanID() !== 0) {
        row.fan = fanMap.get(bin.fanID());
      }
      data.push(row);
      dataMap.set(bin.key(), row);

      //add to the controller numbers
      bin.status.fans.forEach(fan => {
        //add to the total for each fan
        totalControllers++;
        //if the fan is on add to the count for controllers that are on
        if (fan.state) {
          onControllers++;
        }
      });
    });
    setTableData(data);
    //setTableDataMap(dataMap)
    if (getControllerInfo) {
      getControllerInfo(totalControllers, onControllers);
    }
  }, [as, bins, getControllerInfo]);

  const updateBinFan = () => {
    if (selectedBinRow && newFanID) {
      console.log(newFanID);
      //use the bin api to update the bin in the selected row
      let bin = selectedBinRow.bin;
      bin.settings.fanId = newFanID;
      binAPI
        .updateBin(bin.key(), bin.settings)
        .then(resp => {
          //when an update succeeds update the table with the fan information
          //let dataMap = tableDataMap
          selectedBinRow.bin = bin;
          selectedBinRow.fan = fanMap.get(newFanID);
          openSnack("Updated bin with new fan");
          //close dialog
          setOpenPickerDialog(false);
        })
        .catch(err => {
          openSnack("failed to update bin with new fan");
        });
    }
  };

  const fanPickerDialog = () => {
    return (
      <ResponsiveDialog
        open={openPickerDialog}
        onClose={() => {
          setOpenPickerDialog(false);
        }}>
        <DialogContent>
          <FanPicker
            updateFan={fanID => {
              setNewFanID(fanID);
            }}
            useCardView
          />
        </DialogContent>
        <DialogActions>
          <Button>Cancel</Button>
          <Button onClick={updateBinFan}>Confirm</Button>
        </DialogActions>
      </ResponsiveDialog>
    );
  };

  //function to make the table
  const fanStatusTable = () => {
    return (
      <MaterialTable
        style={{ width: "100%" }}
        isLoading={isLoading}
        key="fanStatus"
        icons={getTableIcons()}
        columns={columns}
        actions={
          !isMobile
            ? [
                {
                  icon: () => {
                    return <AerationFanIcon />;
                  },
                  tooltip: "Change fan",
                  onClick: (e, data) => {
                    setSelectedBinRow(data as BinRow);
                    setOpenPickerDialog(true);
                  }
                }
              ]
            : undefined
        }
        data={tableData}
        options={{
          paging: true,
          pageSize: pageSize,
          pageSizeOptions: [10, 20, 30],
          showTitle: true,
          toolbar: true,
          sorting: false,
          columnsButton: false,
          search: true
        }}
        localization={{
          body: { emptyDataSourceMessage: isLoading ? "" : "No Bins found" }
        }}
        title="Fans"
        // components={{
        //   Toolbar: props => (
        //     <React.Fragment>
        //       <MTableToolbar {...props} />
        //       {actionToolbar()}
        //     </React.Fragment>
        //   )
        // }}
        // onRowClick={(event: any, row?: DeviceRow) => {
        //   if (row && row.device && row.device.id()) {
        //     toDevice(row.device.id());
        //   }
        // }}
        onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
      />
    );
  };
  return (
    <React.Fragment>
      {fanStatusTable()}
      {fanPickerDialog()}
    </React.Fragment>
  );
}
