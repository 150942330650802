import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";

export interface INotificationAPIContext {
  listNotifications: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListNotificationsResponse>>;
  hideNotification: (keys: string[]) => Promise<AxiosResponse<pond.HideNotificationResponse>>;
}

export const NotificationAPIContext = createContext<INotificationAPIContext>(
  {} as INotificationAPIContext
);

interface Props {}

export default function NotificationProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, put } = useHTTP();

  const listNotifications = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean
  ) => {
    return get<pond.ListNotificationsResponse>(
      pondURL(
        "/notifications" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const hideNotification = (keys: string[]) => {
    return put<pond.HideNotificationResponse>(
      pondURL("/notifications/hide"),
      pond.NotificationKeys.create({ keys: keys })
    );
  };

  return (
    <NotificationAPIContext.Provider
      value={{
        listNotifications,
        hideNotification
      }}>
      {children}
    </NotificationAPIContext.Provider>
  );
}

export const useNotificationAPI = () => useContext(NotificationAPIContext);
