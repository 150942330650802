import Co2SensorDarkIcon from "assets/components/co2Dark.png";
import Co2SensorLightIcon from "assets/components/co2Light.png";
import {
  ComponentTypeExtension,
  Summary,
  simpleSummaries,
  simpleMeasurements
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function CO2(subtype: number = 0): ComponentTypeExtension {
  let co2 = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPM,
    quack.ComponentType.COMPONENT_TYPE_CO2,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_CO2,
    subtypes: [],
    friendlyName: "CO2",
    description: "Measures the parts per million of CO2",
    isController: false,
    isSource: true,
    isCalibratable: true,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_I2C],
    interactionResultTypes: [],
    states: [],
    measurements: simpleMeasurements(co2),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, co2);
    },
    minMeasurementPeriodMs: 5000,
    icon: (theme?: "light" | "dark"): string | undefined => {
      return theme === "light" ? Co2SensorDarkIcon : Co2SensorLightIcon;
    }
  };
}
