import {
  ComponentMeasurement,
  ComponentTypeExtension,
  GraphFilters,
  Summary
} from "pbHelpers/ComponentType";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";
import { quack } from "protobuf-ts/quack";

export function dragerGasDongle(subtype: number = 0): ComponentTypeExtension {
  let ppm = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_PPM,
    quack.ComponentType.COMPONENT_TYPE_DRAGER_GAS_DONGLE,
    subtype
  );
  let voltage = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE,
    quack.ComponentType.COMPONENT_TYPE_DRAGER_GAS_DONGLE,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_DRAGER_GAS_DONGLE,
    subtypes: [
      {
        key: quack.DragerGasDongleSubtype.DRAGER_GAS_DONGLE_SUBTYPE_NONE,
        value: "DRAGER_GAS_DONGLE_SUBTYPE_NONE",
        friendlyName: "Drager Gas Chain"
      },
      {
        key: quack.DragerGasDongleSubtype.DRAGER_GAS_DONGLE_SUBTYPE_DEBUG_VOLTAGE,
        value: "DRAGER_GAS_DONGLE_SUBTYPE_ADVANCED",
        friendlyName: "Drager Gas Chain - Voltage Debug"
      },
      {
        key: quack.DragerGasDongleSubtype.DRAGER_GAS_DONGLE_SUBTYPE_CO_CO2_NO2_O2,
        value: "DRAGER_GAS_DONGLE_SUBTYPE_ADVANCED",
        friendlyName: "Drager Gas Chain (CO CO2 NO2 O2)"
      }
    ],
    friendlyName: "Drager Gas",
    description: "Drager Sensor that measures density of gases",
    isController: false,
    isSource: true,
    isArray: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY],
    interactionResultTypes: [],
    states: [],
    //this is apparently used by the interactions to determine what the possible options for measurement type are
    measurements: [
      {
        measurementType: quack.MeasurementType.MEASUREMENT_TYPE_PPM,
        label: ppm.label(),
        colour: ppm.colour(),
        graphType: ppm.graph()
      } as ComponentMeasurement,
      {
        measurementType: quack.MeasurementType.MEASUREMENT_TYPE_VOLTAGE,
        label: voltage.label(),
        colour: voltage.colour(),
        graphType: voltage.graph()
      } as ComponentMeasurement
    ],
    //believe this is only used with the old deprecated measurements system, unit measurements builds the summary in the UnitMeasurementSummary component
    measurementSummary: async function(
      measurement: quack.Measurement,
      filters: GraphFilters
    ): Promise<Array<Summary>> {
      let summary: Array<Summary> = [];
      return Promise.resolve(summary);
    },
    minMeasurementPeriodMs: 1000
  };
}
