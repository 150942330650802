import {
  ComponentTypeExtension,
  Summary,
  simpleMeasurements,
  simpleSummaries
} from "pbHelpers/ComponentType";
import { quack } from "protobuf-ts/quack";
import { describeMeasurement } from "pbHelpers/MeasurementDescriber";

export function OnOffInput(subtype: number = 0): ComponentTypeExtension {
  let onOff = describeMeasurement(
    quack.MeasurementType.MEASUREMENT_TYPE_BOOLEAN,
    quack.ComponentType.COMPONENT_TYPE_BOOLEAN_INPUT,
    subtype
  );
  return {
    type: quack.ComponentType.COMPONENT_TYPE_BOOLEAN_INPUT,
    subtypes: [],
    friendlyName: "On/Off Input",
    description: "Measures whether an object is on/off",
    isController: false,
    isSource: true,
    isCalibratable: false,
    addressTypes: [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY],
    interactionResultTypes: [],
    states: onOff.enumerations(),
    measurements: simpleMeasurements(onOff),
    measurementSummary: async function(measurement: quack.Measurement): Promise<Array<Summary>> {
      return simpleSummaries(measurement, onOff);
    },
    minMeasurementPeriodMs: 1000
  };
}
